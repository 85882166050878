import React from "react"
import { graphql } from "gatsby"

import Layout, { Section } from "../components/Layout"
import PageImage from "../components/PageUnsplashImage"
import { WeeklyEvent } from "../components/Events"
const Page = ({ data, location }) => {
  const weeklyEvent = data.weeklyEvent

  return (
    <Layout title={weeklyEvent.frontmatter.title}>
      <div>
        <PageImage src={weeklyEvent.frontmatter.image} credit={weeklyEvent.frontmatter.imageCredit}>
          <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
            {weeklyEvent.frontmatter.title}
          </h1>
        </PageImage>

        <Section className="container is-max-desktop">
          <div className="content markdown">
            <WeeklyEvent event={weeklyEvent} />
          </div>
          <br />
          <br />
        </Section>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query WeeklyEventPage($id: String!) {
    weeklyEvent: markdownRemark(id: { eq: $id }) {
      html
      id
      fields {
        slug
      }
      frontmatter {
        title
        description
        notes
        when
        link
        linkText
        image
        imageCredit {
          author
          href
        }
        promoImage
      }
    }
  }
`
